import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import theme from '../../../theme'
import { useTranslation } from 'react-i18next'
import { Button, Checkbox, FormControlLabel, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import NumberFormat from 'react-number-format'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import apiClient from '../../../services/apiClient'
import { BaseTextField } from '../../textfield/StyledTextField'
import DeleteIcon from '@mui/icons-material/Delete'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import SaveIcon from '@mui/icons-material/Save'
import { myStyles, NotificationAlert } from '../../index'
import AlertBox from '../../alert/AlertBox'

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="(###) ###-####" allowEmptyFormatting mask="_"
    />
  );
});

function TenantsDetails ({ handleNext, handleBack, owner, setOwner }) {
  const {t} = useTranslation();
  const [changeMade, setChangeMade] = useState(false);
  const [errors, setErrors] = useState({});
  const [tenantSucces, setTenantSucces] = useState(false);
  const [errorDuplicateTenantNumbers, setErrorDuplicateTenantNumbers] = useState(false);
  let updatedOwner = { ...owner };

  const { control,reset, handleSubmit, setValue, watch  } = useForm({
    reValidateMode: "onBlur",
    defaultValues: {
      tenants: owner?.tenants || [],
    }
  });

  const { fields: tenants, append: appendTenantsRow, remove: removeTenantsRow } = useFieldArray({
    control,
    name: "tenants",
  });

  const onFieldsChange = (e) => {
    setChangeMade(true);
    setErrorDuplicateTenantNumbers(false);
  };

  const { mutate: crudTenantsList } = useMutation(
    async (tenants) => {
      setErrors({msg: false})
      const tenantsList = {
        tenants: tenants,
        clientReference: owner?.clientReference,
        roadFK: owner.roadFK
      }
      return await apiClient.crudTenantsList(tenantsList);
    },
    {
      onSuccess: () => {
        handleNext()
      },
      onError: (error) => {
        setErrors({msg: true})
      }
    }
  );

  const { mutate: crudTenant } = useMutation(
    async (tenant) => {
      setErrors({msg: false})
      setTenantSucces(false)
      const tenantsList = {
        tenants: [tenant],
        clientReference: owner?.clientReference,
        roadFK: owner.roadFK
      }
      return await apiClient.crudTenantsList(tenantsList);
    },
    {
      onSuccess: (data, tenant) => {
        setOwner(updatedOwner);
        setTenantSucces(true)
        addLog({
          action: `Gestion Locataires - Ajout/Modification Locataire - ${owner.clientReference} - ${tenant?.identification || tenant?.tenantNumber || tenant?.email}`,
          user: 'Propriétaire'
        })
      },
      onError: (error) => {
        setErrors({msg: true})
      }
    }
  );

  const { mutate: addLog } = useMutation(
    async (log) => {
      return await apiClient.addLogGestionLocataire(log);
    },
  );

  const addNewTenants = () => {
    appendTenantsRow({ identification: '', tenantNumber: '', email: '', hasUnsubscribe: false, tenantEmail: false, tenantSMS: false, tenantVoice: false, referenceFK: owner?.clientReference, roadFK: owner?.roadFK })
  };

  const {mutate: deleteTenant} = useMutation(
    async ({ tenantId, index }) => {
      setErrors({msg: false})
      return await apiClient.deleteTenantGestion(tenantId);
    },
    {
      onSuccess: (tenantId, { index }) => {
        const identification = owner.tenants[index]?.identification || 'Identifiant non disponible';
        updatedOwner.tenants = owner.tenants.filter((_, i) => i !== index);
        setOwner(updatedOwner);
        addLog({
          action: `Gestion Locataires - Suppression Locataire - ${owner.clientReference} - ${identification}`,
          user: 'Propriétaire'
        })
      },
      onError: (error) => {
        setErrors({msg: true})
      }
    }
  );

  const handleBackBtn = () => {
    if (changeMade) {
      Swal.fire({
        icon: 'warning',
        title: t('gestionLocataires.DataLost'),
        text: t('gestionLocataires.DataLostText'),
        showCancelButton: true,
        confirmButtonColor: theme.palette.primary.dark,
        confirmButtonText: t('app.Yes'),
        cancelButtonText: t('app.No'),
      }).then((result) => {
        if (result.isConfirmed) {
          reset()
          handleBack()
        }}
      )
    } else {
      handleBack()
    }
  }

  const handleOnSubmit = (values) => {
    const filteredTenants = values.tenants.filter((tenant) =>
      (tenant.identification && tenant.identification.trim() !== '') ||
      (tenant.tenantNumber && tenant.tenantNumber.trim() !== '') ||
      (tenant.email && tenant.email.trim() !== '')
    );
    // Vérifie les duplications
    if (hasDuplicateTenantNumbers(filteredTenants)) {
      setErrorDuplicateTenantNumbers(true);
      Swal.fire({
        icon: 'error',
        title: t('gestionLocataires.DuplicateNumberError'),
        text: t('gestionLocataires.DuplicateNumberErrorMessage'),
      });
      return; // Arrête la soumission si des doublons existent
    } else {
      setErrorDuplicateTenantNumbers(false);
    }
    
    crudTenantsList(filteredTenants);
  };
  
  const hasDuplicateTenantNumbers = (tenants) => {
    const tenantNumbers = tenants.map(tenant => tenant.tenantNumber?.trim()).filter(Boolean);
    const uniqueNumbers = new Set(tenantNumbers);
    return uniqueNumbers.size !== tenantNumbers.length;
  };



  useEffect(() => {
    if (tenants.length === 0) {
      addNewTenants()
    }
  }, [tenants, appendTenantsRow, owner]);

  const saveAndAddNewRow = (index) => {
    handleSubmit((data) => {
      const currentTenant = data.tenants[index];
      updatedOwner.tenants[index] = currentTenant;
      crudTenant(currentTenant)
      owner.tenants[index] = currentTenant;
      addNewTenants()
      setChangeMade(false);
    })();
  };

  const saveTenant = (index) => {
    handleSubmit((data) => {
      const currentTenant = data.tenants[index];
      updatedOwner.tenants[index] = currentTenant;
      crudTenant(currentTenant)
      owner.tenants[index] = currentTenant;
      setChangeMade(false);
    })();
  }

  const handleDeleteTenant = (index) => {
    const tenantId = owner.tenants[index]?.id;
    if (tenantId) {
      deleteTenant({ tenantId, index });
    }
    removeTenantsRow(index);
  };


  return (
    <>
      <NotificationAlert openNotification={errors?.msg} type='error' message={t('gestionLocataires.ErrorWhileSaving')}/>
      <NotificationAlert openNotification={tenantSucces} type='success' message={t('gestionLocataires.TenantSaved')}/>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h2" sx={{mb:0}}>{t('gestionLocataires.Step3')}</Typography>
          <Typography variant="body1" sx={{ mb:1 }}>{t('gestionLocataires.Step3Text')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <AlertBox  type='warning' message={t('gestionLocataires.ConsentementLocataire')}/>
        </Grid>
        <Grid item xs={12}>
          { tenants.map( (field, index) => (
            <Grid container key={field.id} spacing={1} alignItems="center" className="tenant-separator">
              <Grid item xs={4}>
                <Controller
                  control={control}
                  name={`tenants.${index}.identification`}
                  render={({ field, fieldState: { error } }) => (
                    <BaseTextField
                      {...field}
                      fullWidth
                      variant="outlined"
                      label={t('client.Identification')}
                      sx={{ mb: 0 }}
                      onChange={(e) => {
                        field.onChange(e)
                        onFieldsChange()
                      }}
                    />
                  )}
                />
              </Grid>
              
              <Grid item xs={3}>
                <Controller
                  control={control}
                  name={`tenants.${index}.tenantNumber`}
                  render={({ field, fieldState: { error } }) => (
                    <BaseTextField
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        setErrorDuplicateTenantNumbers(false);
                      }}
                      sx={{mb:0}}
                      label={t('client.Phone')}
                      fullWidth
                      id={`tenants.${index}.tenantNumber`}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  control={control}
                  name={`tenants.${index}.email`}
                  rules={{
                    required: false,
                    pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <BaseTextField
                      {...field}
                      id={`tenants.${index}.email`}
                      onChange={(e) => {
                        field.onChange(e)
                        onFieldsChange()
                      }}
                      fullWidth
                      variant="outlined"
                      label={t('client.Email')}
                      sx={{ mb: 0 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                {index === tenants.length - 1 ? (
                  // Icon for adding a new tenant (last row)
                  <Tooltip title={t('gestionLocataires.AddTenant')}>
                  <span className="add-tenant">
                    <IconButton
                      onClick={() => saveAndAddNewRow(index)}
                    >
                      <GroupAddIcon sx={myStyles.iconTitle} />
                    </IconButton>
                  </span>
                      </Tooltip>
                    ) : (
                      // Icon for saving the current tenant (not the last row)
                      <Tooltip title={t('gestionLocataires.SaveTenants')}>
                  <span className="save-tenant">
                    <IconButton
                      onClick={() => saveTenant(index)}
                    >
                      <SaveIcon sx={myStyles.iconTitle} />
                    </IconButton>
                  </span>
                  </Tooltip>
                )}
                <Tooltip title={t('gestionLocataires.Delete')} >
                  <IconButton onClick={() => handleDeleteTenant(index)}>
                    <DeleteIcon sx={myStyles.iconTitle}/>
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={12}  style={{ display: 'block' }}>
                <Controller
                  control={control}
                  name={`tenants.${index}.tenantVoice`}
                  defaultValue={false}
                  render={({ field }) => (
                    <>
                      <FormControlLabel
                        control={<Checkbox checked={field.value} sx={{ pt:0, pb:0 }} {...field} />}
                        label="Téléphone"
                      />
                    </>
                  )}
                />
                <Controller
                  control={control}
                  name={`tenants.${index}.tenantSMS`}
                  defaultValue={false}
                  render={({ field }) => (
                    <>
                      <FormControlLabel
                        control={<Checkbox checked={field.value} sx={{ pt:0, pb:0 }} {...field} />}
                        label="SMS (Texto)"
                      />
                    </>
                  )}
                />
                <Controller
                  control={control}
                  name={`tenants.${index}.tenantEmail`}
                  defaultValue={false}
                  render={({ field }) => (
                    <>
                      <Controller
                        control={control}
                        name={`tenants.${index}.hasUnsubscribe`}
                        render={({ field: { value: hasUnsubscribe } }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={field.value}
                                sx={{ pt: 0, pb: 0 }}
                                {...field}
                                disabled={hasUnsubscribe}
                              />
                            }
                            label="Email"
                          />
                        )}
                      />
                    </>
                  )}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
      {
        errorDuplicateTenantNumbers && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <AlertBox  type='error' message={t('gestionLocataires.DuplicateNumberErrorMessage')}/>
            </Grid>
          </Grid>
        )
      }
      <div className="btn-component">
        <Button className="next-btn" onClick={handleBackBtn} variant="contained" size="meddium" type="submit"
                sx={{ mt: 1, mb: 1 }}>
          {t('app.Back')}
        </Button>
        <Button className="next-btn" onClick={handleSubmit(handleOnSubmit)} variant="contained" size="meddium"
                type="submit"
                sx={{ mt: 1, mb: 1 }}>
          {t('app.Next')}
        </Button>
      </div>
      <div>
        <Grid container spacing={1}>
          <Grid item xs={12} center>
            <Typography variant="h5" sx={{mb:0}}>{t('gestionLocataires.Legend')}</Typography>
          </Grid>
            <Grid item xs={12} md={4}>
            <GroupAddIcon sx={myStyles.smallIconTitle} />
            <Typography variant="body1"
                        sx={{ 'font-size': '12px', mt: 2 }}>{t('gestionLocataires.AddIcon')}</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <SaveIcon sx={myStyles.smallIconTitle} />
            <Typography variant="body1"
                        sx={{ 'font-size': '12px', mt: 2 }}>{t('gestionLocataires.SaveIcon')}</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <DeleteIcon sx={myStyles.smallIconTitle} />
            <Typography variant="body1"
                        sx={{ 'font-size': '12px', mt: 2 }}>{t('gestionLocataires.DeleteIcon')}</Typography>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default TenantsDetails

import React, { useState } from 'react'
import { Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FormHelper, GestionClient, NotificationAlert } from '../../index'
import NumberFormat from 'react-number-format'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import apiClient from '../../../services/apiClient'
import { BaseTextField } from '../../textfield/StyledTextField'
import AlertBox from '../../alert/AlertBox'
import Swal from 'sweetalert2'
import theme from '../../../theme'

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="(###) ###-####" allowEmptyFormatting mask="_"
    />
  );
});

function OwnerDetails ({ handleNext, handleBack, owner, setOwner }) {
  const {t} = useTranslation();
  const [changeMade, setChangeMade] = useState(false);
  const [errors, setErrors] = useState({});
  const [numberError, setNumberError] = useState(false);
  let updatedOwner = {
    ...owner,
  }

  const { control,reset, handleSubmit, setValue, watch  } = useForm({
    reValidateMode: "onBlur",
    defaultValues: {
      clientEmail: owner?.clientEmail || "",
      clientTelNumber: owner?.clientTel || "",
      clientCellNumber: owner?.clientMobile || "",
      clientCallAlert: owner?.clientAlerteAppel || false,
      clientSmsAlert: owner?.clientAlerteSms || false,
      clientAlerteEmail: owner?.clientAlerteEmail || false,
    }
  });

  const onFieldsChange = (e) => {
    setChangeMade(true);
  };

  const { mutate: crudClient } = useMutation(
    async (updatedOwner) => {
      return await apiClient.crudClientsInfos(updatedOwner);
    },
    {
      onSuccess: () => {
        setOwner(updatedOwner)
        setChangeMade(false);
        addLog({
          action: `Gestion Locataires - Update Information Proprio - ${owner?.clientReference || owner?.clientContractNumber || owner?.clientEmail}`,
          user: 'Propriétaire'
        })
        handleNext()
      },
      onError: (error) => {
        setErrors({msg: true})
      }
    }
  );

  const { mutate: addLog } = useMutation(
    async (log) => {
      return await apiClient.addLogGestionLocataire(log);
    },
  );

  const handleOnSubmit = (values) => {
    if (changeMade) {
      updatedOwner = {
        ...owner,
        clientContractNumber: owner.clientContractNumber,
        clientEmail: values.clientEmail,
        clientMobile: values.clientCellNumber,
        clientTel: values.clientTelNumber,
        clientAlerteAppel: values.clientCallAlert,
        clientAlerteSms: values.clientSmsAlert,
        clientAlerteEmail: values.clientAlerteEmail
      }
      const numberFailCheck = checkPhoneNumber(values)
      if(!numberFailCheck){
        crudClient(updatedOwner)
      }
    } else {
      handleNext()
    }
  }

  const checkPhoneNumber = (values) => {
    let error = false
    setNumberError(false)
    
    const telNumber = values.clientTelNumber
    
    if((telNumber === "" || telNumber.length < 10) && values?.clientCallAlert === true){
      error = true
      setNumberError(true)
    }

    const cellNumber = values.clientCellNumber
    if((cellNumber === "" || cellNumber.length < 10) && values?.clientSmsAlert === true){
      error = true
      setNumberError(true)
    }
    return error
  }

  const handleBackBtn = () => {
    if (changeMade) {
      Swal.fire({
        icon: 'warning',
        title: t('gestionLocataires.DataLost'),
        text: t('gestionLocataires.DataLostText'),
        showCancelButton: true,
        confirmButtonColor: theme.palette.primary.dark,
        confirmButtonText: t('app.Yes'),
        cancelButtonText: t('app.No'),
      }).then((result) => {
        if (result.isConfirmed) {
          reset()
          handleBack()
        }}
      )
    } else {
      handleBack()
    }
  }

  return (
    <>
      <NotificationAlert openNotification={errors?.msg} type='error' message={t('client.ErrorFetchingData')}/>
      <Typography variant="h2" sx={{mb:0}}>{t('gestionLocataires.Step2')}</Typography>
      <Typography variant="h6" sx={{fontWeight:'bold'}}>{owner.clientAddresse}</Typography>
      <Typography variant="body1" sx={{ mb:1 }}>{t('gestionLocataires.ContactOwner')}</Typography>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="clientEmail"
            rules={{
              pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
              message: "Mauvais format de courriel"
            }}
            render={({ field, fieldState: { error } }) => (
              <BaseTextField
                {...field}
                fullWidth
                variant="outlined"
                label={t('client.Email')}
                sx={{mb:0}}
                error={error !== undefined}
                helperText={error ? "Mauvais format de courriel" : ""}
                onChange={(e) => {
                  field.onChange(e);
                  onFieldsChange();
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={control}
            name="clientTelNumber"
            render={({ field, fieldState: { error } }) => (
              <BaseTextField
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  onFieldsChange();
                }}
                sx={{mb:0}}
                label={t('client.Phone')}
                fullWidth
                id="formatted-mainPhone-input"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="clientCellNumber"
            render={({ field, fieldState: { error } }) => (
              <BaseTextField
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  onFieldsChange();
                }}
                sx={{mb:0}}
                label={t('client.Cellphone')}
                fullWidth
                id="formatted-cellPhone-input"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt:2 }}>
          <Controller
            control={control}
            name="clientCallAlert"
            defaultValue={false}
            render={({ field }) => (
              <>
                <FormControlLabel
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  onChange={(e) => {
                    setChangeMade(true)
                    field.onChange(e);
                    onFieldsChange();
                  }}
                  control={
                    <Checkbox
                      checked={field.value}
                      sx={{ pt:0, pb:0 }}
                      {...field}
                      onChange={(e) => {
                        setChangeMade(true);
                        field.onChange(e);
                        onFieldsChange(e);
                      }}
                    />
                  }
                  label={t('client.PhoneAlert')}
                />
              </>
            )}
          />
        </Grid>
        <Grid item xs={4} style={{ display: 'flex', alignItems: 'left', justifyContent: 'center', mt:2 }} >
          <Controller
            control={control}
            name="clientSmsAlert"
            defaultValue={false}
            render={({ field }) => (
              <>
                <FormControlLabel
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  onChange={(e) => {
                    setChangeMade(true)
                    field.onChange(e);
                    onFieldsChange();
                  }}
                  control={
                    <Checkbox
                      checked={field.value}
                      sx={{ pt:0, pb:0 }}
                      {...field}
                      onChange={(e) => {
                        setChangeMade(true);
                        field.onChange(e);
                        onFieldsChange(e);
                      }}
                    />
                  }
                  label={t('client.SMSAlert')}
                />
              </>
            )}
          />
        </Grid>
        <Grid item xs={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt:2 }} >
          <Controller
            control={control}
            name="clientAlerteEmail"
            defaultValue={false}
            render={({ field }) => (
              <>
                <FormControlLabel
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  onChange={(e) => {
                    setChangeMade(true)
                    onFieldsChange();
                    field.onChange(e);
                  }}
                  control={
                    <Checkbox
                      checked={field.value}
                      sx={{ pt:0, pb:0 }}
                      {...field}
                      onChange={(e) => {
                        setChangeMade(true);
                        field.onChange(e);
                        onFieldsChange(e);
                      }}
                    />
                  }
                  label={t('client.EmailAlert')}
                />
              </>
            )}
          />
        </Grid>
      </Grid>
      {
        numberError && (
          <div className="owner-step-error">
            <AlertBox type={'error'} message={t('gestionLocataires.InvalidNumber')}/>
          </div>
        )
      }
      {
        errors?.msg && (
          <div className="owner-step-error">
            <AlertBox type={'error'} message={t('gestionLocataires.ErrorFetchingData')}/>
          </div>
        )
      }
      <div className="btn-component">
        <Button className="next-btn" onClick={handleBackBtn} variant="contained" size="meddium" type="submit"
                sx={{ mt: 1, mb: 1 }}>
          {t('app.Back')}
        </Button>
        <Button className="next-btn" onClick={handleSubmit(handleOnSubmit)} variant="contained" size="meddium" type="submit"
                sx={{ mt: 1, mb: 1 }}>
          {t('app.Next')}
        </Button>
      </div>
      <div>
        <Typography variant="body1" sx={{ 'font-size': '12px', mt: 2 }}>{t('gestionLocataires.NextActionSave')}</Typography>
      </div>
    </>
  )
}

export default OwnerDetails
